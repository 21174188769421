<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data () {
    return {
      showConsoleLogo: true,
      logos: {
        xeno: { width: 200, height: 65, img: require('./assets/img/logos/logo_xeno_stroke_anim_1.svg') },
        slaask: { width: 220, height: 65, img: require('./assets/img/logos/logo_slaask_stroke_anim_1.svg') },
        sarbacane: { width: 400, height: 65, img: require('./assets/img/logos/logo_sarbacane_stroke_anim_1.svg') }
      },
      appName: this.$appParams.appName.toLowerCase()
    }
  },
  mounted () {
    const logo = this.logos[this.appName] || this.logos.xeno
    if (this.showConsoleLogo) console.log('%c ', 'box-sizing: content-box; padding: ' + (logo.height * 0.5) + 'px ' + (logo.width * 0.5) + 'px; height: ' + logo.height + 'px; width: ' + logo.width + 'px; ' + (window.chrome ? '' : 'line-height: ' + logo.height + 'px;') + ' background-position: center; background-repeat: no-repeat; background-size: 100%; background-image: url( ' + logo.img + ')')
    console.log('%c Connection %c ' + this.$appParams.appName.toUpperCase() + ' v' + this.$appParams.appVersion + ' %c',
      'background: #35495e; cursor: default; padding: 1px; box-sizing: content-box; border-radius: 4px 0 0 4px;  color: #fff',
      'background: #23aa5c; cursor: default; padding: 1px; box-sizing: content-box; border-radius: 0 4px 4px 0;  color: #fff',
      'background: transparent; cursor: pointer;'
    )
  }
}
</script>

<style>

* {
  box-sizing: border-box;
}

</style>
