
<template>
  <div class="no-select">
    <div class="decor">
      <svg style="display: none;">
        <symbol id="shape_1" viewBox="0 0 351.04 289.98"><path d="M29.49,64c-74,130.3,5.62,226,81.17,226,86.67,0,262.87-95.65,238-211C327.42-19.24,81.55-27.69,29.49,64Z" /></symbol>
        <symbol id="shape_2" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></symbol>
      </svg>

      <svg class="shape_1">
        <use xlink:href="#shape_1" />
      </svg>

      <svg class="shape_2">
        <use xlink:href="#shape_2" />
      </svg>

      <div class="sputnik">
        <img :src="require('@/feature/sign_in/assets/img/sputnik.svg')" />
      </div>

      <div class="voyager">
        <img :src="require('@/feature/sign_in/assets/img/voyager.svg')" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Decor'
}

</script>

<style scoped>

.decor {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
}
.decor .shape_1 {
  position: absolute;
  width: 96%;
  height: 90%;
  top: 10%;
  fill: var(--color-success-faded-background);
  filter: brightness(.82) brightness(1.2);
}
.decor .shape_2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 4%;
  fill: var(--color-background-12dp);
}
.decor .voyager {
  position: absolute;
  width: 64%;
  left: 28%;
  top: 44%;
  transform: translateY(-50%);
}
.decor .sputnik {
  position: absolute;
  width: 37%;
  left: 18%;
  top: 82%;
  transform: translateY(-50%);
}
.decor .noxe {
  position: absolute;
  left: 5%;
  top: 84%;
  transform: rotate(10deg);
}
.decor .noxe img,
.decor .voyager img,
.decor .sputnik img {
  height: 100%;
  width: 100%;
}

</style>
