
import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/feature/sign_in/components/Index'

Vue.use(Router)

export default new Router({
  routes: [
    /* ROUTES */

    {
      path: '/signin',
      name: 'Signin',
      component: Index
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Index
    },
    {
      path: '/password',
      name: 'Password',
      component: Index
    },
    {
      path: '/password_edit',
      name: 'PasswordEdit',
      component: Index
    },
    {
      path: '/password_email_send',
      name: 'PasswordEmailSend',
      component: Index
    },

    /* REDIRECTIONS */

    {
      path: '/',
      redirect: '/signin'
    },
    {
      path: '/*',
      redirect: '/signin'
    }
  ]
})
