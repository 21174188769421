
<template>
  <div class="_container">
    <div class="bg_shape">
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
    </div>

    <div class="sign_in_up" @click="goToPage(linkSignUp.route)" v-html="linkSignUp.content" />

    <div class="_content">
      <div class="title" v-html="title" />

      <Decor class="illustration" />

      <div class="message" v-html="message" />

      <div class="links">
        <div @click="goToPage('/password')">
          {{ change_email }}
        </div>
        <div @click="goToPage('/signin')">
          {{ signin }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Decor from '@/feature/sign_in/components/decor/Decor'

export default {
  name: 'PasswordEmailSend',
  components: {
    Decor
  },
  props: {
    email: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
      linkSignUp: {
        content: '<span>' + window.I18n.t('login.shared.not_have_account') + '</span> <span>' + window.I18n.t('register.title') + '</span>',
        route: '/signup'
      },
      signin: window.I18n.t('login.shared.title'),
      change_email: window.I18n.t('login.shared.change_email'),
      title: window.I18n.t('login.shared.reset_your_password').replace(/\n/g, '<br/>'),
      /* eslint-disable-next-line no-template-curly-in-string */
      message: window.I18n.t('login.shared.send_email').replace('${email}', this.$route.query.email ? '<b>' + this.$route.query.email + '</b>' : 'null')
    }
  },
  async mounted () {
    if (this.$appParams.hideBadgeRecaptcha) {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.hideBadge()
    }
  },
  methods: {
    goToPage (route) {
      this.$router.push(route)
    }
  }
}

</script>

<style scoped src="@/feature/sign_in/assets/css/page.css"></style>

<style scoped>

._container ._content .title {
  text-align: left;
  margin-bottom: 50px;
}

._container ._content .illustration {
  margin-bottom: 50px;
}

._container ._content .message {
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  color: var(--color-neutral-faded-text-d);
  border: 1px solid var(--color-background-mention-me);
  background-color: var(--color-neutral-faded-background);
}

._container ._content .links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}
._container ._content .links div {
  position: relative;
  cursor: pointer;
  transition: .1s;
  color: var(--color-text-light);
}
._container ._content .links div:hover {
  color: var(--color-text);
}
._container ._content .links div:before {
  position: absolute;
  content: ' ';
  height: 1px;
  width: 100%;
  left: 0px;
  top: 100%;
  background-color: currentColor;
  transform-origin: left;
  transform: scaleX(0);
  transition: inherit;
}
._container ._content .links div:hover:before {
  transform: scaleX(1);
}

</style>
