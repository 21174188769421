
<template>
  <div class="_container">
    <div class="bg_shape">
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
    </div>

    <div class="sign_in_up" @click="goToPage(linkSignUp.route)" v-html="linkSignUp.content" />

    <div class="_content_row">
      <div class="_content content_form">
        <div class="title" v-html="title" />

        <Form :data="form" :show-tooltip="true" />
      </div>

      <div class="_content content_illustration">
        <Decor class="illustration" />

        <TextEfx class="text_efx" />

        <div class="join_team">
          <i class="fas fa-question-circle" />
          <span>
            {{ joinTeam.content }}
            <div class="tooltip">{{ joinTeam.tooltip }}</div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Decor from '@/feature/sign_in/components/decor/Decor'
import Form from '@/feature/sign_in/components/utils/form/Form'
import TextEfx from '@/feature/sign_in/components/utils/TextEfx'
import { getCsrfToken, getInputByName } from '@/feature/sign_in/helpers'

const zxcvbn = require('zxcvbn')

export default {
  name: 'SignUp',
  components: {
    Form,
    Decor,
    TextEfx
  },
  data () {
    return {
      title: this.$appParams.invitation ? window.I18n.t('register.join') : window.I18n.t('register.title'),
      linkSignUp: {
        content: '<span>' + window.I18n.t('login.shared.have_account') + '</span> <span>' + window.I18n.t('login.shared.title') + '</span>',
        route: '/signin'
      },
      joinTeam: {
        content: window.I18n.t('register.join'),
        tooltip: window.I18n.t('register.needs_invite')
      },
      reCaptchaError: 'Human verification failed. If this error persists, come live chat with us. We\'ll be happy to help you.',
      form: {
        submit: this.submit,
        action: '/users',
        method: 'POST',
        inputs: [
          this.$appParams.invitation
            ? false
            : {
                type: 'google_connect',
                content: window.I18n.t('register.register_with', { provider: 'Google' }),
                href: '/users/auth/google'
              },
          this.$appParams.invitation
            ? false
            : {
                type: 'separator',
                content: window.I18n.t('register.or')
              },
          [
            {
              type: 'text',
              name: 'user[firstname]',
              icon: 'fal fa-user',
              placeholder: window.I18n.t('register.firstname'),
              onInput: this.autoCompleteNickname,
              onBlur: this.autoCompleteNickname,
              value: this.$appParams.invitation && this.$appParams.invitation.user && this.$appParams.invitation.user.firstname ? this.$appParams.invitation.user.firstname : '',
              controller: {
                error: false,
                list: [
                  [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.firstname') })]
                ]
              }
            },
            {
              type: 'text',
              name: 'user[lastname]',
              icon: 'fal fa-user',
              placeholder: window.I18n.t('register.lastname'),
              onInput: this.autoCompleteNickname,
              onBlur: this.autoCompleteNickname,
              value: this.$appParams.invitation && this.$appParams.invitation.user && this.$appParams.invitation.user.lastname ? this.$appParams.invitation.user.lastname : '',
              controller: {
                error: false,
                list: [
                  [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.lastname') })]
                ]
              }
            }
          ],
          {
            type: 'text',
            name: 'user[email]',
            icon: 'fal fa-envelope',
            placeholder: window.I18n.t('register.email'),
            autocomplete: 'new-password',
            value: this.$appParams.invitation && this.$appParams.invitation.user && this.$appParams.invitation.user.email ? this.$appParams.invitation.user.email : '',
            controller: {
              error: false,
              list: [
                [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.email') })],
                [(input) => { return !/\S+@\S+\.\S+/.test(input.value) }, window.I18n.t('register.validations.not_valid_email')]
              ]
            }
          },
          // {
          //   type: 'text',
          //   name: 'tel',
          //   icon: 'fal fa-mobile-android',
          //   placeholder: 'Téléphone (Facultatif)',
          //   value: ''
          // },
          [
            {
              type: 'text',
              name: 'user[nickname]',
              icon: 'fal fa-smile',
              placeholder: window.I18n.t('register.nickname'),
              onInput: this.inputNicknameChange,
              value: this.$appParams.invitation && this.$appParams.invitation.user && this.$appParams.invitation.user.nickname ? this.$appParams.invitation.user.nickname : '',
              controller: {
                error: false,
                list: [
                  [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.nickname') })]
                ]
              }
            },
            {
              type: 'text',
              name: 'user[team_name]',
              icon: 'fal fa-heart',
              placeholder: window.I18n.t('register.team_name'),
              value: this.$appParams.invitation && this.$appParams.invitation.team && this.$appParams.invitation.team.name ? this.$appParams.invitation.team.name : '',
              disabled: this.$appParams.invitation !== false,
              controller: {
                error: false,
                list: [
                  [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.team_name') })]
                ]
              }
            }
          ],
          {
            type: 'separator',
            style: { opacity: '0' }
          },
          {
            type: 'password',
            name: 'user[password]',
            icon: 'fal fa-lock-alt',
            placeholder: window.I18n.t('register.password'),
            autocomplete: 'new-password',
            onInput: this.updateScorePassword,
            hidePassword: true,
            score: 0,
            value: '',
            controller: {
              error: false,
              list: [
                [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.password') })],
                [(input) => { return input.score < 3 }, window.I18n.t('register.validations.password_too_weak_simple')]
              ]
            }
          },
          {
            type: 'password',
            name: 'user[password_confirmation]',
            icon: 'fal fa-lock-alt',
            placeholder: window.I18n.t('register.password_confirm'),
            onInput: this.isConfirmPassword,
            isConfirmPassword: false,
            hidePassword: true,
            value: '',
            controller: {
              error: false,
              list: [
                [(input) => { return input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.password') })],
                [(input) => { return !input.isConfirmPassword }, window.I18n.t('register.validations.password_did_not_match')]
              ]
            }
          },
          {
            type: 'error',
            name: 'alert',
            value: { errors: this.$appParams.flashAlert ? { flashAlert: { message: this.$appParams.flashAlert } } : {}, messages: {} }
          },
          {
            type: 'submit',
            value: window.I18n.t('register.register'),
            style: { 'margin-top': '10px' }
          },
          [
            {
              type: 'hidden',
              name: 'authenticity_token',
              value: getCsrfToken()
            },
            {
              type: 'hidden',
              name: 'utf8',
              value: '✓'
            },
            {
              type: 'hidden',
              name: 'recaptcha_token',
              value: ''
            },
            {
              type: 'hidden',
              name: 'request_type',
              value: this.$appParams.submitJs ? 'js' : ''
            },
            /* IS PARTNER */
            !this.$appParams.is_partner
              ? false
              : {
                  type: 'hidden',
                  name: 'is_partner',
                  value: this.$appParams.is_partner
                },
            /* IF CODE */
            !this.$appParams.code
              ? false
              : {
                  type: 'hidden',
                  name: 'code',
                  value: this.$appParams.code
                }
          ],
          /* IF INVITATION */
          !this.$appParams.invitation
            ? []
            : [
                {
                  type: 'hidden',
                  name: 'user[invitation_id]',
                  value: this.$appParams.invitation.invitation_id
                },
                {
                  type: 'hidden',
                  name: 'user[role_id]',
                  value: this.$appParams.invitation.user.role_id
                },
                {
                  type: 'hidden',
                  name: 'user[team_id]',
                  value: this.$appParams.invitation.team.uid
                },
                {
                  type: 'hidden',
                  name: 'user[user_group_ids]',
                  value: this.$appParams.invitation.user.user_group_ids.join(' ')
                }
              ]
        ]
      },
      inputs: {},
      inputNicknameChanged: false
    }
  },
  async mounted () {
    if (!this.$appParams.hideBadgeRecaptcha) {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    }
    this.inputs = {
      inputFirstname: getInputByName(this.form.inputs, 'user[firstname]'),
      inputLastname: getInputByName(this.form.inputs, 'user[lastname]'),
      inputNickname: getInputByName(this.form.inputs, 'user[nickname]')
    }
  },
  beforeDestroy () {
    if (!this.$appParams.hideBadgeRecaptcha) this.$recaptchaInstance.hideBadge()
  },
  methods: {
    goToPage (route) {
      this.$router.push(route)
    },
    inputNicknameChange () {
      this.inputNicknameChanged = true
    },
    autoCompleteNickname () {
      if (!this.inputNicknameChanged) {
        this.inputs.inputNickname.value = this.inputs.inputFirstname.value.replace(/\s/g, '-').toLowerCase() + (this.inputs.inputLastname.value !== '' ? '-' : '') + this.inputs.inputLastname.value.replace(/\s/g, '-').toLowerCase()
        if (window.document.querySelector('[name="user[nickname]"]')) {
          this.$nextTick(() => {
            window.document.querySelector('[name="user[nickname]"]').dispatchEvent(new Event('input'))
            this.inputNicknameChanged = false
          })
        }
      }
    },
    isConfirmPassword (input) {
      const temp = this.form.inputs.find(input => input.name === 'user[password]')
      input.isConfirmPassword = input.value !== '' && temp.value === input.value
    },
    updateScorePassword (input) {
      const score = zxcvbn(input.value)
      if (score.score !== undefined) input.score = score.score
    },
    async initTokenRecaptcha () {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      const inputReCaptcha = getInputByName(this.form.inputs, 'recaptcha_token')
      inputReCaptcha.value = token
    },
    async submit (event) {
      event.preventDefault()
      event.stopPropagation()
      await this.initTokenRecaptcha()
      if (this.$appParams.submitJs) this.$emit('submit', this.form)
      else event.currentTarget.submit()
    }
  }
}

</script>

<style scoped src="@/feature/sign_in/assets/css/page.css"></style>

<style scoped>

._container .bg_shape .shape {
  height: 150%;
  width: 160%;
  left: -30%;
  top: -25%;
}

._container ._content_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
._container ._content_row .content_form {
  width: 500px;
}
@media screen and (max-width: 540px) {
  ._container ._content_row .content_form {
    width: auto;
  }
}
._container ._content_row .content_form /deep/ .title {
  text-align: left;
  color: var(--color-text);
}
._container ._content_row .content_form /deep/ .form {
  margin-top: 10px;
}
._container ._content_row .content_illustration {
  justify-content: center;
  min-width: 300px;
  margin-left: 5%;
}
._container ._content_row .content_illustration .illustration {
  height: 240px;
  margin-bottom: 10px;
  justify-content: start;
}
@media screen and (max-width: 950px) {
  ._container ._content_row .content_illustration {
    margin-left: 0%;
    min-width: 250px;
  }
  ._container ._content_row .content_illustration .illustration {
    height: 200px;
  }
}
@media screen and (max-width: 900px) {
  ._container ._content_row .content_illustration {
    display: none;
  }
}
._container ._content_row .content_illustration .illustration /deep/ .decor {
  width: 280px;
}
._container ._content_row .content_illustration .illustration /deep/ .decor .sputnik {
  width: 42%;
  left: 12%;
  top: 74%;
}
._container ._content_row .content_illustration .illustration /deep/ .decor .voyager {
  width: 76%;
  top: 46%;
  left: 24%;
}
._container ._content_row .content_illustration .text_efx {
  margin-top: 30px;
  cursor: default;
}
._container ._content_row .content_illustration .text_efx,
._container ._content_row .content_illustration .join_team {
  margin-left: 10px;
}
._container ._content_row .content_illustration .join_team {
  position: relative;
  margin-top: 30px;
  cursor: default;
}
._container ._content_row .content_illustration .join_team i {
  margin-right: 5px;
}
._container ._content_row .content_illustration .join_team span {
  position: relative;
}
._container ._content_row .content_illustration .join_team span:before {
  position: absolute;
  content: ' ';
  height: 1px;
  width: 100%;
  left: 0px;
  top: 100%;
  background-color: currentColor;
  transform: scaleX(0);
  transition: .3s;
}
._container ._content_row .content_illustration .join_team span:hover:before {
  transform: scaleX(1);
}
._container ._content_row .content_illustration .join_team span .tooltip {
  position: absolute;
  width: 260px;
  left: 50%;
  bottom: 100%;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 7px;
  pointer-events: none;
  background-color: rgba(0,0,0,.9);
  transform: translate(-50%, 3px);
  transition: .3s;
  color: #fff;
  opacity: 0;
}
._container ._content_row .content_illustration .join_team span .tooltip:before {
  position: absolute;
  display: block;
  content: ' ';
  height: 0px;
  width: 0px;
  top: 100%;
  left: 50%;
  color: rgba(0,0,0,.9);
  border-style: solid;
  border-width: 6px 7px 0 7px;
  border-color: currentColor transparent transparent transparent;
  transform: rotate(360deg) translateX(-50%);
  pointer-events: none;
}
._container ._content_row .content_illustration .join_team span:hover .tooltip {
  transform: translate(-50%, 0px);
  opacity: 1;
}

</style>
