
<template>
  <div id="page_connection">
    <div>
      <SignUp v-if="$route.name === 'Signup'" @submit="submit" />

      <SignIn v-else-if="$route.name === 'Signin'" :email="emailSync" @syncEmail="syncEmail" @submit="submit" />

      <Password v-else-if="$route.name === 'Password'" :email="emailSync" @syncEmail="syncEmail" @submit="submit" />

      <PasswordEdit v-else-if="$route.name === 'PasswordEdit'" @submit="submit" />

      <PasswordEmailSend v-else-if="$route.name === 'PasswordEmailSend'" @submit="submit" />

      <Error404 v-else />
    </div>

    <a class="logo no-select" href="https://xenoapp.com">
      <img :src="logo[$appParams.appName.toLowerCase()]" />
    </a>

    <transition name="anim_show">
      <div v-if="loading" class="loading">
        <Loader color="color_3" />
      </div>
    </transition>
  </div>
</template>

<script>

import SignIn from './pages/Signin'
import SignUp from './pages/Signup'
import Password from './pages/Password'
import Error404 from './pages/Error404'
import PasswordEdit from './pages/PasswordEdit'
import PasswordEmailSend from './pages/PasswordEmailSend'
import { sendRequest, getInputByName } from '@/feature/sign_in/helpers'
import Loader from './utils/Loader'

export default {
  name: 'Index',
  components: {
    Loader,
    SignIn,
    SignUp,
    Password,
    PasswordEdit,
    PasswordEmailSend,
    Error404
  },
  data () {
    return {
      emailSync: '',
      logo: {
        xeno: require('@/feature/sign_in/assets/img/logos/logo_xeno_move_anim_1.svg'),
        slaask: require('@/feature/sign_in/assets/img/logos/logo_slaask_stroke_anim_1.svg'),
        sarbacane: require('@/feature/sign_in/assets/img/logos/sarbacane-grey.svg')
      },
      loading: false,
      attempt: 0
    }
  },
  methods: {
    goToPage (route) {
      this.$router.push(route)
    },
    syncEmail (email) {
      this.emailSync = email
    },
    async submit (data) {
      this.attempt++
      this.loading = true
      const response = await sendRequest(data)
      // console.log('response -> ', response)
      const error = getInputByName(data.inputs, 'alert')

      if (response.error) {
        this.loading = false
        if (error) error.value = { errors: { server: { message: response.error } }, messages: {} }
        else console.log('ERROR: ', response.error)
        if (response.input_name && document.querySelector('[name="' + response.input_name + '"]')) document.querySelector('[name="' + response.input_name + '"]').focus()
      } else {
        if (response.redirect_to || (response.redirected && response.url)) {
          const url = response.redirect_to ? response.redirect_to : response.url
          if (error) error.value = { messages: { server: { message: '<a href="' + url + '">' + url + '</a>', icon: 'fas fa-spinner fa-pulse' } }, errors: {} }
          else console.log('ERROR REDIRECTION: ', url)
          setTimeout(() => { this.loading = false }, 10000)
          window.location.href = url
        } else {
          console.log('ERROR: ', response.redirect_to, response.url)
          this.loading = false
        }
        if (this.attempt >= 2) {
          this.$appParams.submitJs = false
          console.log('Disabling JavaScript mode')
        }
      }
    }
  }
}

</script>

<style scoped>

#page_connection {
  color: var(--color-text);
}
#page_connection {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  overflow: auto;
}
#page_connection > .logo {
  position: fixed;
  height: 28px;
  width: auto;
  left: 42px;
  top: 36px;
  z-index: 10;
}
#page_connection > .logo img {
  height: 100%;
}
@media screen and (max-width: 1200px) {
  #page_connection > .logo {
    position: absolute;
  }
}
@media screen and (max-width: 500px) {
  #page_connection > .logo {
    left: 30px;
  }
}
#page_connection .loading {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 100;
}
#page_connection .loading:before {
  position: absolute;
  content: ' ';
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  opacity: .6;
  background-color: var(--color-background-24dp);
}

/* ANIM */
.anim_show-enter-active, .anim_show-enter-active /deep/ ._container .loading { transition: .5s; }
.anim_show-leave-active, .anim_show-leave-active /deep/ ._container .loading { transition: .25s; }

.anim_show-enter,
.anim_show-leave-to {
  opacity: 0;
}
.anim_show-leave,
.anim_show-enter-to {
  opacity: 1;
}
.anim_show-enter /deep/ ._container .loading,
.anim_show-leave-to /deep/ ._container .loading {
  transform: rotate(-45deg) scale(.7);
}
.anim_show-leave /deep/ ._container .loading,
.anim_show-enter-to /deep/ ._container .loading {
  transform: rotate(0deg) scale(1);
}

</style>
