
<template>
  <label class="_label">
    <input type="checkbox" :checked="checked" :disabled="disabled" style="display: none;" @input="$emit('input', $event.target.checked)" />
    <div class="_checkbox">
      <span class="_box" />
    </div>
  </label>
</template>

<script>

export default {
  name: 'Checkbox',
  props: {
    checked: {
      required: false,
      type: Boolean,
      default: false
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}

</script>

<style scoped>

._label {
  /*--input-color: var(--color-neutral-solid-background);*/
  --input-color: #5089e8;
  --input-color--line: #fff;
  --input-color--border: rgba(0, 0, 0, .08627);
}
/**/
._label {
  cursor: pointer;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border-left: 1px solid transparent;
  padding: 0;
}
._label input[type=checkbox] {
  display: none;
}
._label ._checkbox {
  display: flex;
  font-size: 13px;
  text-rendering: optimizeLegibility;
  box-sizing: content-box;
  margin-right: 7px;
  cursor: pointer;
}
._label ._checkbox ._box {
  display: inline-block;
  width: 4px;
  height: 4px;
  width: 13px;
  height: 13px;
  box-sizing: content-box;
  background-color: var(--input-color--border);
  background-color: var(--color-background-0dp-d);
  border-radius: calc(13px * .266);
  border: 1px solid transparent;
  transition: background-color .1s ease;
  transition-delay: .05s;
}
._label ._checkbox ._box:after,
._label ._checkbox ._box:before {
  position: absolute;
  content: '';
  width: 0;
  transform-origin: 0 100%;
  border-bottom: 1px solid var(--input-color--line);
}
._label ._checkbox ._box:before {
  transition: width .05s linear;
  transition-delay: .1s;
  transform: rotate(45deg) translate(5px, 3px);
}
._label ._checkbox ._box:after {
  transition: width .1s linear;
  transition-delay: 0s;
  transform: rotate(-45deg) translate(-2px, 10px);
}
/* ANIM CHECKED */
@keyframes wobble {
  0% { transform: scale(1) rotate(0deg) }
  40% { transform: scale(1.1, .9) rotate(5deg) }
  70% { transform: scale(.9, 1.1) rotate(-3deg) }
  80% { transform: scale(1.05, .95) rotate(1deg) }
  to { transform: scale(1) rotate(0deg) }
}
._label input[type=checkbox]:checked:not(:disabled) ~ ._checkbox ._box {
  height: 13px;
  width: 13px;
  transition-delay: 0s;
  animation-name: wobble;
  animation-duration: .3s;
  animation-timing-function: ease;
  background-color: var(--input-color);
  border: 1px solid var(--input-color--border);
}
._label input[type=checkbox]:checked:not(:disabled) ~ ._checkbox ._box:before {
  width: calc(13px / 2 - 2px);
  transition-delay: 0s;
}
._label input[type=checkbox]:checked:not(:disabled) ~ ._checkbox ._box:after {
  width: calc(13px / 2 + 2px);
  transition-delay: .05s;
}
/* DISABLED */
._label input[type=checkbox]:disabled ~ ._checkbox ._checkbox {
  opacity: .6;
  cursor: not-allowed;
}

</style>
