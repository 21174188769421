
<template>
  <form class="form" :action="data.action" :method="data.method" @submit="errors ? submit($event, data.submit) : (data.submit ? data.submit($event) : undefined)">
    <div v-for="(inputRow, key) in form" :key="'input_row' + key" class="input_row">
      <div v-for="(input, key2) in inputRow" :key="'input_' + key + '_' + key2 + '_' + input.name" class="input_container">
        <!-- GOOGLE CONNECT -->
        <!-- <Oauth2Google v-if="input.type === 'google_connect'"></Oauth2Google> -->
        <a v-if="input.type === 'google_connect'" :href="input.href" class="google_connect" :style="input.style">
          <span class="logo"><img :src="require('@/feature/sign_in/assets/img/icon_google.svg')" /></span>
          <span class="content">{{ input.content }}</span>
        </a>

        <!-- SEPARATOR -->
        <div v-else-if="input.type === 'separator'" class="separator" :style="input.style">
          <div v-if="input.content">
            {{ input.content }}
          </div>
        </div>

        <!-- BUTTON -->
        <button v-else-if="input.type === 'button'" :style="input.style" @click="input.validate()">
          {{ input.content }}
        </button>

        <!-- LINK -->
        <div v-else-if="input.type === 'link'" class="link" :style="input.style">
          <div @click="goToPage(input.route)">
            {{ input.content }}
          </div>
        </div>

        <!-- CHECKBOX -->
        <div v-else-if="input.type === 'checkbox'" class="checkbox no-select" :style="input.style">
          <label>
            <Checkbox v-model="input.value" :checked="input.value" @input="input.keepValue ? saveLsValue(input.name, input.value) : undefined" />
            <!-- <input type="checkbox" v-model="input.value" :checked="input.value"> -->
            <span v-if="input.label">{{ input.label }}</span>
          </label>
        </div>

        <!-- INPUT HIDDEN -->
        <div v-else-if="input.type === 'hidden'" class="input input_hidden">
          <input v-model="input.value" type="hidden" :name="input.name" />
        </div>

        <!-- INPUT TEXT -->
        <div v-else-if="input.type === 'text'" class="input input_text" :class="{ 'input_error': input.controller && input.controller.error && input.controller.error !== false }">
          <input :ref="input.name ? 'input_' + input.name : undefined" v-model="input.value" type="text" :name="input.name" :disabled="input.disabled ? true : false" :placeholder="input.placeholder" :autocomplete="input.autocomplete" :style="input.style" @input="input.onInput ? input.onInput(input) : undefined; input.controller ? controller(input) : undefined" @blur="input.onBlur ? input.onBlur(input) : undefined" @animationstart="input.autoFill !== undefined ? autoFillOnInput(input, $event) : undefined" />
          <i v-if="input.icon" class="icon fa-fw" :class="input.icon" />
          <div v-if="showTooltip" class="tooltip">
            {{ input.placeholder }}
          </div>
        </div>

        <!-- INPUT PASSWORD -->
        <div v-else-if="input.type === 'password'" class="input input_password" :class="{ 'input_error': input.controller && input.controller.error, 'input_password_with_score': input.score !== undefined && input.value !== '' }">
          <input :ref="input.name ? 'input_' + input.name : undefined" v-model="input.value" :type="!input.hidePassword ? 'text' : 'password'" :name="input.name" :placeholder="input.placeholder" :autocomplete="input.autocomplete" :style="input.style" @input="input.onInput ? input.onInput(input) : undefined; input.controller ? controller(input) : undefined" @blur="input.onBlur ? input.onBlur(input) : undefined" @animationstart="input.autoFill !== undefined ? autoFillOnInput(input, $event) : undefined" />
          <i v-if="input.icon || input.isConfirmPassword" class="icon fa-fw" :class="input.isConfirmPassword ? 'fas fa-check' : input.icon" />
          <div class="icon_show_password" @click.stop="togglePassword(input, $event)">
            <i v-if="input.hidePassword" class="fal fa-fw fa-eye" />
            <i v-else class="fal fa-fw fa-eye-slash" />
          </div>
          <div v-if="input.score !== undefined && input.value !== ''" class="score_password" :class="'score_password_' + input.score">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path class="bg_score" d="M288,32C128.9,32,0,160.9,0,320c-0.1,50.9,13.4,100.9,39.1,144.8c5.6,9.6,16.3,15.2,27.4,15.2h443 c11.1,0,21.8-5.6,27.4-15.2c25.6-43.9,39.1-93.9,39.1-144.8C576,160.9,447.1,32,288,32z" />
              <path v-if="input.score === 0" d="M343.12 416H232.88a65.78 65.78 0 0 1-6.9-18L92.05 375.67a24 24 0 0 1 7.9-47.34l133.68 22.28A63.77 63.77 0 0 1 343.12 416z" />
              <path v-else-if="input.score === 1" d="M343.12 416H232.88a62.26 62.26 0 0 1 .47-64.86L124.8 206.41a24 24 0 0 1 38.41-28.81l108.56 144.74A63.5 63.5 0 0 1 343.12 416z" />
              <path v-else-if="input.score === 2" d="M264 324.75V128a24 24 0 0 1 48 0v196.75A63.36 63.36 0 0 1 343.12 416H232.88A63.36 63.36 0 0 1 264 324.75z" />
              <path v-else-if="input.score === 3" d="M288 320a63.14 63.14 0 0 1 16.24 2.34L412.8 177.59a24 24 0 1 1 38.4 28.82L342.65 351.14a62.26 62.26 0 0 1 .47 64.86H232.88a63.33 63.33 0 0 1-8.88-32 64 64 0 0 1 64-64z" />
              <path v-else-if="input.score === 4" d="M484 375.67L350 398a66 66 0 0 1-6.9 18H232.88a63.33 63.33 0 0 1-8.88-32 63.85 63.85 0 0 1 118.37-33.39l133.68-22.28a24 24 0 0 1 7.9 47.34z" />
            </svg>
          </div>
          <div v-if="showTooltip" class="tooltip">
            {{ input.placeholder }}
          </div>
        </div>

        <!-- ERROR FORM -->
        <div v-else-if="input.type === 'error'" class="container_alerts" :class="{ 'no_error': (input.value.errors === false || Object.keys(input.value.errors).length === 0) && (input.value.messages === false || Object.keys(input.value.messages).length === 0) }">
          <div v-for="(value, errorKey) in input.value.errors" :key="'error_' + errorKey" class="_alert _alert_error" :class="{ '_alert_corrected': value.corrected }">
            <svg v-if="!value.corrected" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="icon_warning">
              <path d="M569.52 440L329.58 24c-18.44-32-64.69-32-83.16 0L6.48 440c-18.42 31.94 4.64 72 41.57 72h479.89c36.87 0 60.06-40 41.58-72zM288 448a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 297.52 352h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 265.68 192h44.64a16 16 0 0 1 15.92 17.59z" />
              <path d="M310.32 192h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 278.48 352h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 310.32 192zM288 384a32 32 0 1 0 32 32 32 32 0 0 0-32-32z" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
            </svg>
            <span v-html="value.message" />
          </div>
          <div v-for="(value, messageKey) in input.value.messages" :key="'message_' + messageKey" class="_alert _alert_message">
            <svg v-if="!value.icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z" />
            </svg>
            <i v-else :class="value.icon" />
            <span v-html="value.message" />
          </div>
        </div>

        <slot v-else-if="input === false" />

        <!-- ELSE -->
        <div v-else>
          <input :ref="input.name ? 'input_' + input.name : undefined" v-model="input.value" :type="input.type" :name="input.name" :class="{ 'submit_disabled': buttonIsDisabled }" :placeholder="input.placeholder" :style="input.style" @input="input.onInput ? input.onInput(input) : undefined; input.controller ? controller(input) : undefined" @blur="input.onBlur ? input.onBlur(input) : undefined" @animationstart="input.autoFill !== undefined ? autoFillOnInput(input, $event) : undefined" />
          <i v-if="input.icon" class="icon fa-fw" :class="input.icon" />
          <div v-if="showTooltip" class="tooltip">
            {{ input.placeholder }}
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>

import { getInputByName } from '@/feature/sign_in/helpers'
import Checkbox from '@/feature/sign_in/components/utils/form/inputs/Checkbox'

export default {
  name: 'Form',
  components: {
    Checkbox
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: () => {}
    },
    showTooltip: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      errors: undefined,
      errorsList: {},
      checkAutoFill: {}
    }
  },
  computed: {
    form () {
      return this.data.inputs.map(input => Array.isArray(input) ? input : [input])
    },
    buttonIsDisabled () {
      return !Object.values(this.errorsList).every(v => v === false)
    }
  },
  mounted () {
    this.placeholder_efx()
    this.errors = getInputByName(this.data.inputs, 'alert')
    this.controlAlInputs(true)
    if (this.data.autofocus && this.$refs['input_' + this.data.autofocus][0]) this.$refs['input_' + this.data.autofocus][0].focus()
  },
  methods: {
    autoFillOnInput (input, event) {
      input.autoFill = event.animationName.indexOf('onautofillstart') > -1
      this.$nextTick(() => { this.controlAlInputs(true) })
    },
    goToPage (route) {
      this.$router.push(route)
    },
    togglePassword (input, event) {
      input.hidePassword = !input.hidePassword
      this.$refs['input_' + input.name][0].focus()
    },
    placeholder_efx () {
      const duration = 200
      const a = document.querySelectorAll('[placeholder]')
      for (let i = 0; i < a.length; i++) {
        const txt = a[i].getAttribute('placeholder')
        const delay = duration / txt.length
        a[i].addEventListener('focus', () => {
          if (a[i].value.trim() === '') {
            a[i].value = ''
            let t = txt
            for (let j = 0; j < t.length; j++) {
              setTimeout(() => {
                t = t.substring(0, t.length - 1)
                a[i].setAttribute('placeholder', t)
              }, delay * j)
            }
          }
        })
        a[i].addEventListener('blur', () => {
          if (a[i].value.trim() === '') {
            a[i].value = ''
            let t = ''
            for (let j = 0; j < txt.length; j++) {
              setTimeout(() => {
                t += txt[j]
                a[i].setAttribute('placeholder', t)
              }, delay * j * 0.5)
            }
          }
        })
      }
    },
    saveLsValue (lsName, value) {
      this.$ls.set(lsName, value)
    },
    /* ERRORS CONTROLLER */
    submit (event, submit) {
      if (this.controlAlInputs()) {
        if (submit) submit(event)
      } else {
        event.stopPropagation()
        event.preventDefault()
      }
    },
    controller (input, onlyTest = true) {
      const errors = []
      if (this.errors.value.errors.server) delete this.errors.value.errors.server
      input.controller.list.forEach((control, key) => {
        if (control[0](input)) {
          if (this.errors.value.errors['error_' + input.name + '_' + key] || !onlyTest) {
            this.$set(this.errors.value.errors, 'error_' + input.name + '_' + key, { message: control[1], corrected: false })
            errors.push(true)
          }
          this.$set(this.errorsList, 'error_' + input.name + '_' + key, true)
        } else {
          if (this.errors.value.errors['error_' + input.name + '_' + key]) this.errors.value.errors['error_' + input.name + '_' + key].corrected = true
          this.$set(this.errorsList, 'error_' + input.name + '_' + key, false)
        }
      })
      input.controller.error = errors.length > 0
      return input.controller.error
    },
    controlAlInputs (onlyTest = false) {
      const errors = []
      this.data.inputs.forEach(a => {
        if (Array.isArray(a)) a.forEach(input => { if (input.controller !== undefined) { errors.push(this.controller(input, onlyTest)) } })
        else if (a.controller !== undefined && a.name !== undefined) { errors.push(this.controller(a, onlyTest)) }
      })
      return errors.every(v => v === false)
    }
  }
}

</script>

<style scoped src="@/feature/sign_in/assets/css/form.css"></style>
