
<template>
  <div class="_container">
    <div class="bg_shape">
      <!-- <svg class="shape" viewBox="0 0 351.04 289.98"><path d="M29.49,64c-74,130.3,5.62,226,81.17,226,86.67,0,262.87-95.65,238-211C327.42-19.24,81.55-27.69,29.49,64Z"/></svg> -->
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
      <svg class="shape" viewBox="0 0 327.01 331"><path d="M97.52,328.2C12.26,311-41.32,70.63,40.85,22.2,180.27-60,322.22,104.92,327,210.59,330.09,281,182.79,345.35,97.52,328.2Z" /></svg>
    </div>

    <div class="sign_in_up" @click="goToPage(linkSignUp.route)" v-html="linkSignUp.content" />

    <div class="_content">
      <div class="title" v-html="title" />

      <Decor class="illustration" />

      <Form :data="form" />
    </div>
  </div>
</template>

<script>

import Decor from '@/feature/sign_in/components/decor/Decor'
import Form from '@/feature/sign_in/components/utils/form/Form'
import { getCsrfToken } from '@/feature/sign_in/helpers'

export default {
  name: 'SignIn',
  components: {
    Form,
    Decor
  },
  props: {
    email: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
      title: window.I18n.t('login.shared.title'),
      linkSignUp: {
        content: '<span>' + window.I18n.t('login.shared.not_have_account') + '</span> <span>' + window.I18n.t('register.title') + '</span>',
        route: '/signup'
      },
      form: {
        submit: this.submit,
        action: '/users/sign_in',
        method: 'POST',
        inputs: [
          {
            type: 'google_connect',
            content: window.I18n.t('register.sign_in_with', { provider: 'Google' }),
            href: '/users/auth/google'
          },
          {
            type: 'separator',
            content: window.I18n.t('register.or')
          },
          {
            type: 'text',
            name: 'user[email]',
            icon: 'fal fa-envelope',
            placeholder: window.I18n.t('widget.email_placeholder_content'),
            onInput: this.syncEmail,
            value: this.email,
            autoFill: false,
            controller: {
              error: false,
              list: [
                [(input) => { return input.autoFill === true ? false : input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('roles.index.email') })],
                [(input) => { return input.autoFill === true ? false : !/\S+@\S+\.\S+/.test(input.value) }, window.I18n.t('register.validations.not_valid_email')]
                // [ (input) => { if (input.autoFill === true) { return false } else { return input.value.trim() === '' } }, window.I18n.t('register.validations.required', { input: window.I18n.t('roles.index.email') }) ],
                // [ (input) => { if (input.autoFill === true) { return false } else { return !/\S+@\S+\.\S+/.test(input.value) } }, window.I18n.t('register.validations.not_valid_email') ]
              ]
            }
          },
          {
            type: 'password',
            name: 'user[password]',
            icon: 'fal fa-lock-alt',
            placeholder: window.I18n.t('register.password'),
            hidePassword: true,
            value: '',
            autoFill: false,
            controller: {
              error: false,
              list: [
                [(input) => { return input.autoFill === true ? false : input.value.trim() === '' }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.password') })]
                // [ (input) => { if (input.autoFill === true) { return false } else { return input.value.trim() === '' } }, window.I18n.t('register.validations.required', { input: window.I18n.t('register.password') }) ]
              ]
            }
          },
          [
            {
              type: 'checkbox',
              name: 'user[remember_me]',
              label: window.I18n.t('login.shared.remember_me'),
              style: { marginTop: '5px', display: 'inline-block' },
              value: this.$ls.get('user[remember_me]', true),
              keepValue: true
            },
            {
              type: 'link',
              content: window.I18n.t('login.shared.forgot_password'),
              style: { 'margin-top': '5px' },
              route: '/password'
            }
          ],
          [
            {
              type: 'hidden',
              name: 'authenticity_token',
              value: getCsrfToken()
            },
            {
              type: 'hidden',
              name: 'utf8',
              value: '✓'
            },
            {
              type: 'hidden',
              name: 'request_type',
              value: this.$appParams.submitJs ? 'js' : ''
            }
          ],
          {
            type: 'submit',
            value: window.I18n.t('shared.not_logged_in.sign_in')
          },
          {
            type: 'error',
            name: 'alert',
            value: { errors: this.$appParams.flashAlert ? { flashAlert: { message: this.$appParams.flashAlert } } : {}, messages: {} }
            // value: { errors: { server: { message: 'Error', corrected: false } }, messages: {} }
          }
        ]
        // autofocus: 'user[email]'
      }
    }
  },
  async mounted () {
    if (this.$appParams.hideBadgeRecaptcha) {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.hideBadge()
    }
  },
  methods: {
    goToPage (route) {
      this.$router.push(route)
    },
    selectLogo () {
      const logo = {
        xeno: require('@/feature/sign_in/assets/img/logos/logo_xeno_stroke_anim_1.svg'),
        slaask: require('@/feature/sign_in/assets/img/logos/logo_slaask_stroke_anim_1.svg'),
        sarbacane: require('@/feature/sign_in/assets/img/logos/logo_sarbacane_stroke_anim_1.svg')
      }
      return logo[this.$appParams.appName.toLowerCase()]
    },
    syncEmail (input) {
      this.$emit('syncEmail', input.value)
    },
    submit (event) {
      if (this.$appParams.submitJs) {
        event.preventDefault()
        event.stopPropagation()
        this.$emit('submit', this.form)
      }
    }
  }
}

</script>

<style scoped src="@/feature/sign_in/assets/css/page.css"></style>
