
<template>
  <div class="container_text_efx no-select">
    <span class="text_efx">
      <span v-if="$appParams.locale === 'fr'" class="anim_title anim_title_1">
        <div v-for="(text, key) in textAnim.before" :key="'before_1_' + key" :data-content-text="text[0]" :data-id="key">
          {{ text[0] }}
        </div>
      </span>
      <span>
        <span> {{ textAnim.conversationn }}</span><span class="anim_title anim_title_2">
          <div v-for="(text, key) in textAnim.before" :key="'before_2_' + key" :data-content-text="text[1]" :data-id="key">
            {{ text[1] }}
          </div>
        </span>
      </span>
      <span v-if="$appParams.locale !== 'fr'" class="anim_title anim_title_1">
        <div v-for="(text, key) in textAnim.before" :key="'before_1_' + key" :data-content-text="text[0]" :data-id="key">
          {{ text[0] }}
        </div>
      </span>
      <br />
      <span class="anim_title anim_title_3">
        <div v-for="(text, key) in textAnim.after" :key="'after_' + key" :data-content-text="text" :data-id="key">
          {{ text }}
        </div>
      </span>
    </span>
  </div>
</template>

<script>

import { TextEffect } from '@/feature/sign_in/helpers'

export default {
  name: 'TextEfx',
  data () {
    return {
      fr: {
        before: [
          ['Intelligence artificielle', 'elle'],
          ['Chatbot', 'el'],
          ['Ventes', 'elles'],
          ['Boîte de réception', 'elle'],
          ['Sondages', 'els'],
          ['Prise de rendez-vous', 'elle'],
          ['Enquêtes de satisfaction', 'elles'],
          ['Enquêtes de satisfaction', 'elles'],
          ['Relation client', 'elle'],
          ['Support client', 'el'],
          ['Marketing', 'el'],
          ['Messagerie omnicanale', 'elle'],
          ['Base de connaissances', 'elle'],
          ['Scénarios', 'els'],
          ['Expérience client', 'elle'],
          ['B2C', 'el'],
          ['B2B', 'el'],
          ['Prospection', 'elle']
        ],
        conversationn: 'conversationn',
        after: [
          // 'pour convertir votre trafic web en revenus.',
          'pour retargeter vos clients.',
          'pour éviter de vous déplacer.',
          'pour améliorer votre bilan carbone.',
          // 'pour faire de votre entreprise une entreprise résiliente.',
          'pour vendre 24h/24 et 7j/7.',
          'pour améliorer votre CSAT.',
          'pour améliorer votre NPS.',
          // 'pour augmenter votre chiffre d\'affaires.',
          // 'pour augmenter votre taux de conversion.',
          'pour fidéliser vos clients.',
          // 'pour accélérer votre transformation digitale.',
          // 'pour augmenter l’efficacité de votre équipe.',
          // 'pour augmenter le ROI de vos dépenses marketing.',
          'pour digitaliser vos ventes.',
          // 'pour raccourcir votre cycle de ventes.',
          // 'pour améliorer votre expérience de marque.',
          // 'pour mieux comprendre ce que vos clients veulent.',
          // 'pour automatiser votre processus de ventes.',
          // 'pour développer vos relations clients à distance.',
          // 'pour réduire le volume de vos appels entrants',
          'pour vous faire gagner du temps'
        ]
      },
      en: {
        before: [
          ['artificial intelligence', ''],
          ['chatbot', ''],
          ['sales', ''],
          ['inbox', ''],
          ['surveys', ''],
          ['meeting scheduler', ''],
          ['customer satisfaction', ''],
          ['customer relationships', ''],
          ['customer support', ''],
          ['marketing', ''],
          ['omnichannel inbox', ''],
          ['knowledge base', ''],
          ['flows', ''],
          ['customer experience', ''],
          ['B2C', ''],
          ['B2B', ''],
          ['lead generation', '']
        ],
        conversationn: 'Conversational',
        after: [
          'to convert your web traffic into revenue.',
          'to retarget your customers.',
          'to avoid unnecessary traveling.',
          'to improve your carbon footprint.',
          'to make your business more resilient.',
          'to sell your products and services 24/7.',
          'to improve your CSAT.',
          'to improve your NPS.',
          'to increase your sales pipeline.',
          'to improve your conversion rate.',
          'to retain your customers.',
          'to accelerate your digital transformation.',
          'to scale your team efficiency.',
          'to increase your marketing expenses ROI.',
          'to digitize your sales.',
          'to shorten your sales cycle length.',
          'to improve your brand image.',
          'to better understand your customers\' needs.',
          'to automate your sales workflow.',
          'to develop your customer relationships remotely.',
          'to reduce your incoming call volume',
          'to save you time'
        ]
      }
    }
  },
  computed: {
    textAnim () {
      return this[this.$appParams.locale === 'fr' ? 'fr' : 'en']
    }
  },
  mounted () {
    /* eslint-disable no-new */
    new TextEffect('.text_efx')
  }
}

</script>

<style scoped>

.container_text_efx {
  font-size: 20px;
  font-weight: bold;
  width: 330px;
  height: 80px;
}

.text_efx {
  white-space: nowrap;
}
.text_efx .anim_title {
  position: relative;
  display: inline-block;
  vertical-align: top;
  height: inherit;
  overflow: hidden;
  white-space: nowrap;
  transition: 1s;
}
.text_efx .anim_title > div {
  position: absolute;
  display: inline-block;
  transition: 1s;
  opacity: 0;
  left: 0px;
}
.text_efx .anim_title > div._show {
  position: relative;
  opacity: 1;
}

.text_efx .anim_title.anim_title_1 > div {
  transform: translateY(-80%);
}
.text_efx .anim_title.anim_title_1 > div._show {
  transform: translateY(0%);
}
.text_efx .anim_title.anim_title_1 > div._show ~ div {
  transform: translateY(80%);
}

.text_efx .anim_title.anim_title_2 > div {
  transform: translateX(-50%);
}
.text_efx .anim_title.anim_title_2 > div._show {
  transform: translateX(0%);
}
.text_efx .anim_title.anim_title_2 > div._show ~ div {
  transform: translateX(50%);
}

.text_efx .anim_title.anim_title_3 {
  overflow: visible;
  white-space: normal;
  width: 100% !important;
  height: auto;
}
.text_efx .anim_title.anim_title_3 > div {
  transition: .5s;
  transition-delay: 0s;
  transform-origin: left center;
  transform: scaleX(.99);
}
.text_efx .anim_title.anim_title_3 > div._show {
  transition: 1s;
  transition-delay: .5s;
  transform: scaleX(1);
}
.text_efx .anim_title.anim_title_3 > div._show ~ div {
  transform: scaleX(.99);
}

@media (max-width: 800px) {
  .text_efx ._nowrap {
    white-space: normal;
  }
}

</style>
