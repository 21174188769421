
import Vue from 'vue'
import App from './App'
import Storage from 'vue-ls'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// import GAuth from 'vue-google-oauth2'

Vue.config.productionTip = false

/* STORAGE */

const storageOptions = {
  namespace: 'connection__',
  name: 'ls',
  storage: 'local'
}

Vue.use(Storage, storageOptions)

/* RECAPTCHA-V3 */

Vue.use(VueReCaptcha, {
  siteKey: window.recaptchaSitekey,
  loaderOptions: {
    autoHideBadge: true,
    renderParameters: {
      badge: 'bottomleft'
    }
  }
})

/* Google Oauth2 */

// const gauthOption = {
//   clientId: '640025228959-m61s85onj4jf15ul0t7hc72lpka1leim.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'select_account'
// }
// Vue.use(GAuth, gauthOption)

/* PARAMS GLOBALS */

Vue.prototype.$appParams = {
  ios: navigator.userAgent.indexOf('Mac OS X') !== -1 ? 'mac' : 'pc',
  isMobile: window.isMobile(),
  appName: window.xsAppName,
  appVersion: window.xenoVersion,
  isProduction: window.isProduction,
  isXeno: window.xsAppName === 'Xeno',
  isSlaask: window.xsAppName === 'Slaask',
  invitation: window.invitation ? window.invitation : false,
  submitJs: window.flashAlert === false, /* FORCE SUBMIT CLASSIQUE */
  // submitJs: false,
  is_partner: window.is_partner,
  flashAlert: window.flashAlert,
  hideBadgeRecaptcha: false,
  locale: window.locale,
  code: window.code
}

/* eslint-disable no-new */
export default new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>'
})
