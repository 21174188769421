
<template>
  <div class="_container">
    <div class="loading" :class="getClassColor() + ' ' + (small ? 'small' : '')">
      <div class="ring">
        <div /><div /><div /><div />
      </div>
      <div class="ring">
        <div /><div /><div /><div />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Loader',
  props: {
    color: {
      required: false,
      type: String,
      default: ''
    },
    small: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      listClassColor: ['color_1', 'color_2', 'color_3']
    }
  },
  methods: {
    getClassColor () {
      return this.color === 'random' ? this.listClassColor[Math.floor(Math.random() * this.listClassColor.length)] : this.color
    }
  }
}

</script>

<style scoped>

._container {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/* LOADING */
._container .loading {
  --size: 58px;
  --width: 6px;
  --color_outside: var(--color-warn-faded-background-d);
  --color_inside: var(--color-brand-faded-background-d);
  --coef_speed_outside: 1;
  --coef_speed_inside: 1.333;
}
._container .loading.small {
  --size: 16px;
  --width: 3px;
}
._container .loading {
  position: relative;
  height: var(--size);
  width: var(--size);
  transform-origin: center;
}
._container .loading:not(.small) {
  margin-top: -10vh;
}
._container .loading .ring {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
._container .loading .ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border-radius: 50%;
  border-style: solid;
}

._container .loading.color_anim .ring:nth-child(1) div {
  animation: color_anim_outside 4s linear infinite, loader-ring calc(1.2s * var(--coef_speed_outside)) cubic-bezier(.5, 0, .5, 1) infinite;
}
._container .loading.color_anim .ring:nth-child(2) div {
  animation: color_anim_inside 4s linear infinite, loader-ring calc(1.2s * var(--coef_speed_inside)) cubic-bezier(.5, 0, .5, 1) infinite;
}

/* RING 1 */
._container .loading .ring:nth-child(1) {
  height: var(--size);
  width: var(--size);
}
._container .loading .ring:nth-child(1) div {
  height: calc(var(--size) - var(--width));
  width: calc(var(--size) - var(--width));
  border-width: var(--width);
  border-color: var(--color_outside) transparent transparent transparent;
}
._container .loading .ring:nth-child(1) div { animation: loader-ring calc(1.2s * var(--coef_speed_outside)) cubic-bezier(.5, 0, .5, 1) infinite; }
._container .loading .ring:nth-child(1) div:nth-child(1) { animation-delay: calc(-0.45s * var(--coef_speed_outside)); }
._container .loading .ring:nth-child(1) div:nth-child(2) { animation-delay: calc(-0.3s * var(--coef_speed_outside)); }
._container .loading .ring:nth-child(1) div:nth-child(3) { animation-delay: calc(-0.15s * var(--coef_speed_outside)); }

/* RING 2 */
._container .loading .ring:nth-child(2) {
  height: calc(var(--size) - calc(var(--width) * 2));
  width: calc(var(--size) - calc(var(--width) * 2));
}
._container .loading .ring:nth-child(2) div {
  height: calc(var(--size) - calc(var(--width) * 3));
  width: calc(var(--size) - calc(var(--width) * 3));
  border-width: var(--width);
  border-color: var(--color_inside) transparent transparent transparent;
}
._container .loading .ring:nth-child(2) div { animation: loader-ring calc(1.2s * var(--coef_speed_inside)) cubic-bezier(.5, 0, .5, 1) infinite; }
._container .loading .ring:nth-child(2) div:nth-child(1) { animation-delay: calc(-0.45s * var(--coef_speed_inside)); }
._container .loading .ring:nth-child(2) div:nth-child(2) { animation-delay: calc(-0.3s * var(--coef_speed_inside)); }
._container .loading .ring:nth-child(2) div:nth-child(3) { animation-delay: calc(-0.15s * var(--coef_speed_inside)); }

/* LINEAR */
._container .loading.linear .ring:nth-child(1) div { animation: loader-ring calc(1.2s * var(--coef_speed_outside)) linear infinite; }
._container .loading.linear .ring:nth-child(2) div { animation: loader-ring calc(1.2s * var(--coef_speed_inside)) linear infinite; }

/* LIGHT */
._container .loading.color_light {
  --color_inside: var(--color-background-24dp);
  --color_outside: var(--color-neutral-faded-background-d);
}
._container .loading.color_light_i {
  --color_inside: var(--color-neutral-faded-background-d);
  --color_outside: var(--color-background-24dp);
}
/* LIGHT 2 */
._container .loading.color_light2 {
  --color_inside: var(--color-background-0dp);
  --color_outside: var(--color-neutral-faded-background-d);
}
._container .loading.color_light2_i {
  --color_inside: var(--color-neutral-faded-background-d);
  --color_outside: var(--color-background-0dp);
}
/* NEUTRAL */
._container .loading.color {
  --color_outside: var(--color-neutral-faded-background-d);
  --color_inside: var(--color-text-light);
}
._container .loading.color_i {
  --color_outside: var(--color-text-light);
  --color_inside: var(--color-neutral-faded-background-d);
}
._container .loading.color_grey {
  --color_outside: var(--color-background-24dp-d);
  --color_inside: var(--color-text-light);
}
._container .loading.color_grey_i {
  --color_outside: var(--color-text-light);
  --color_inside: var(--color-background-24dp-d);
}
/* BRAND */
._container .loading.color_brand {
  --color_outside: var(--color-brand);
  --color_inside: var(--color-text-light);
}
._container .loading.color_brand_i {
  --color_outside: var(--color-text-light);
  --color_inside: var(--color-brand);
}
/* COLOR */
._container .loading.color_1 {
  --color_outside: var(--color-warn-faded-background-d);
  --color_inside: var(--color-brand-faded-background-d);
}
._container .loading.color_2 {
  --color_outside: var(--color-neutral-faded-background-d);
  --color_inside: var(--color-warn-faded-background-d);
}
._container .loading.color_3 {
  --color_outside: var(--color-brand-faded-background-d);
  --color_inside: var(--color-secondary-faded-background-d);
}

/**/
@keyframes loader-ring {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes color_anim_outside {
  0% { border-color: var(--color-neutral-faded-background-d) transparent transparent transparent; }
  33% { border-color: var(--color-warn-faded-background-d) transparent transparent transparent; }
  66% { border-color: var(--color-brand-faded-background-d) transparent transparent transparent; }
  100% { border-color: var(--color-neutral-faded-background-d) transparent transparent transparent; }
}
@keyframes color_anim_inside {
  0% { border-color: var(--color-warn-faded-background-d) transparent transparent transparent; }
  33% { border-color: var(--color-brand-faded-background-d) transparent transparent transparent; }
  66% { border-color: var(--color-secondary-faded-background-d) transparent transparent transparent; }
  100% { border-color: var(--color-warn-faded-background-d) transparent transparent transparent; }
}

</style>
